<template>
	<div class="zui-flex-column zui-align-center zui-h-100">
		<div class="top" :style="topNote">
			<div class="content">
				<div class="jinColor zui-font-lg zui-p-b-20">{{ detail.settingType }}</div>
				<div class="desc1"  v-html="detail.settingContent" style="white-space: pre-line;text-indent: 2em;text-align: justify;"></div>
			</div>
		</div>
		<div class="line" style="margin-bottom: 120px;">
			<span class="circle circle1"></span>
			<span class="circle circle2"></span>
			<span class="circle circle3"></span>
		</div>
		<div class="zuiBody">
			<div class="jinColor zui-font-lg zui-p-b-20">{{ $t('companyProfile.leader') }}</div>
			<div class="zui-flex-column">
				<div class="topLine"></div>
				<div class="itemBox zui-w-100 zui-flex-row" v-for="(item, index) in detail3" :key="index">
					<el-image class="img" :src="$apiUrl + '/' + item.img" fit="cover"></el-image>
					<div class="item zui-flex-1">
						<div class="name">{{ item.leaderName }}</div>
						<div class="desc zui-p-y-10">{{ item.leaderPostion }}</div>
			<div class="content" style="white-space: pre-line;text-indent: 2em;text-align: justify;" v-html="item.leaderDesc" ></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 买卖模块 -->
		<!-- <div class="line zui-m-y-50">
			<span class="circle circle1"></span>
			<span class="circle circle2"></span>
			<span class="circle circle3"></span>
		</div> -->
		<!-- <div class="zui-flex-row zui-w-100 zui-m-y-20 pBox">
			<div v-for="(item, index) in $t('companyProfile.serviceArr')" :key="index" class="zui-flex-1 zui-flex-column zui-justify-around zui-align-center">
				<div class="p1">{{ item.title1 }}</div>
				<div class="p2">{{ item.title2 }}</div>
				<div class="p3">{{ item.title3 }}</div>
				<div class="p3">{{ item.title4 }}</div>
				<div @click="goToUs(index)" class="moreBtn1 zui-pointer">{{ item.title5 }}</div>
			</div>
		</div> -->
		<div class=" zui-m-t-20">
			<!-- <span class="circle circle1"></span>
			<span class="circle circle2"></span>
			<span class="circle circle3"></span> -->
		</div>
		<div class="line"  style="margin: 150px auto 0;">
			<span class="circle circle1"></span>
			<span class="circle circle2"></span>
			<span class="circle circle3"></span>
		</div>
		<div class="companyProfile_page" :style="topNote1"></div>
		<div class=" zui-m-b-10">
			<!-- <span class="circle circle1"></span>
			<span class="circle circle2"></span>
			<span class="circle circle3"></span> -->
		</div>
		<!-- 公司景愿 -->
		<!-- <div class="zui-flex-row zui-w-100 zui-p-10" :style="{ height: screenWidth / 2 + 'px' }">
			<el-image class="zui-flex-1" :src="$apiUrl + '/' + titlebg1" fit="fill"></el-image>
			<div style="width: 1%;"></div>
			<el-image class="zui-flex-1" :src="$apiUrl + '/' + titlebg2" fit="fill"></el-image>
		</div> -->
	</div>
</template>

<script>
import { getAirComCulture, getAirComSetting, getAirLeaderIntorduce } from '../api/api';
import { mapState } from "vuex";
export default {
	data() {
		return {
			detail: '',
			detail2: [],
			titlebg1: '',
			titlebg2: '',
			detail3: [],
			screenWidth: 1920,
			bgImg:''
		};
	},
	computed: {
		...mapState(["language"]),
		topNote() {
			if (this.detail) {
				let bg = this.$apiUrl + '/' + this.detail.settingImg;
				var height;
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					height = (900 / 1920) * this.screenWidth + 'px';
				} else {
					height = (600 / 1920) * this.screenWidth + 'px';
				}

				let dom = {
					backgroundImage: 'url(' + bg + ')',
					height
				};
				return dom;
			} else {
				return {};
			}
		},
		topNote1() {
			let bg = this.$apiUrl + '/' + this.bgImg.settingImg;
			var height;
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				height = (850 / 1920) * this.screenWidth + 'px';
			} else {
				height = (1000 / 1920) * this.screenWidth + 'px';
			}
			let dom = {
				backgroundImage: 'url(' + bg + ')',
				height
			};
			return dom;
		}
	},
	created: async function() {
		
		await this.airComSetting();
		await this.airComCulture();	

	},
	watch:{
		language(event){
			
				if(event=='en'){
					this.detail.settingType = 'COMPANY INTRODUCTION' 
				}else{
					this.detail.settingType = '公司简介'
				}
			
		}
	},
	mounted: function() {
	
		// 首次加载时,需要调用一次
		this.screenWidth = window.innerWidth;
		// console.log(this.screenWidth);
		// 窗口大小发生改变时,调用一次
		this.setSize();
		// 窗口大小发生改变时,调用一次
		window.onresize = () => {
			this.screenWidth = window.innerWidth;

			this.setSize();
		};
	},
	methods: {
		goToUs(index = 0) {
			// this.$router.push('/contactUs/'+(Number(index) + 1));
			this.$router.push({ name: 'contactUs', params: { id: index + 1 } });
		},
		setSize: function() {
			// 通过浏览器宽度(图片宽度)计算高度
			this.bannerHeight = (650 / 1920) * this.screenWidth;
		},
		airComSetting() {
			getAirComSetting().then(res => {
				// console.log(res);
				if (res.code == 200) {
					let data=res.result.records[0]
					this.detail = res.result.records[0];
					this.bgImg =res.result.records[4]
					let str=data.settingContent
						var str2=str.replace(/\n/g,"\n<span style='margin-left:2em;'></span>")
						data.settingContent=str2
						this.detail=data
					if(this.language=='en'){
						this.detail.settingType = 'COMPANY INTRODUCTION' 
					}else{
						this.detail.settingType = '公司简介'
					}
					//console.log(str2)
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					// console.log(res.message);
				}
			});
		},
		airComCulture() {
			getAirComCulture().then(res => {
				// console.log(res);
				if (res.code == 200) {
					// console.log(res);
					// console.log(res.result.records[0].titlebg);
					this.titlebg1 = res.result.records[0].titleBg;
					this.titlebg2 = res.result.records[1].titleBg;
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					// console.log(res.message);
				}
			});
			getAirLeaderIntorduce().then(res => {
				// console.log(res);
				if (res.code == 200) {
					// console.log(res);
					//增加缩进
					for(var index in res.result.records){
						var str=res.result.records[index].leaderDesc
						// console.log(str)
						var str2=str.replace(/\n/g,"\n<span style='margin-left:2em;'></span>")
						res.result.records[index].leaderDesc=str2
					}
					this.detail3=res.result.records
				
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					// console.log(res.message);
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.top {
	//height: 300px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	.content {
		width: 80%;
		.desc {
			font-size: 10px;
			max-width: 347px;
			max-height: 139px;
			text-indent: 2em;
			font-family: Microsoft YaHei;
			line-height: 21px;
			font-weight: 400;
		}
		.desc1 {
			text-align: justify;
			font-size: 10px;
			max-width: 410px;
			//max-height: 139px;
			text-indent: 2em;
			font-family: Microsoft YaHei;
			line-height: 21px;
			font-weight: 400;
			width: 45%;
		}
	}
}
.zuiBody {
	width: 80%;
	.topLine {
		width: 230px;
	}
	
	.itemBox {
		.img {
			border-top: 1px solid #dbb965;
			padding-top: 10px;
			padding-right: 10px;
			box-sizing: border-box;
			width: 220px;
			padding-bottom: 10px;
			img {
				width: 100%;
			}
		}
		.item {
			border-left: 1px solid #dbb965;
			width: 80%;
			box-sizing: border-box;
			padding-top: 10px;
			padding-left: 10px;
			min-height: 260px;
			.name {
				font-size: 18px;
				font-weight: bold;
			}
			.desc {
				font-size: 18px;
			}
			.content {
				font-size: 10px;
				text-indent: 0.8rem;
				font-family: Microsoft YaHei;
				line-height: 21px;
				font-weight: 400;
			}
		}
	}
	.itemBox:nth-child(3){
		.img{
			border-bottom: 1px solid #dbb965;
		}
	}
}
.pBox {
	color: black;
	.p1 {
		font-size: 24px;
		font-weight: bold;
	}
	.p2 {
		font-size: 14px;
		padding: 8px 0;
	}
	.p3 {
		font-size: 14px;
		margin-top: 15px;
	}
	.moreBtn1 {
		margin: 30px 0 50px;
		text-align: center;
		padding: 5px 10px;
		box-sizing: border-box;
		color: #dab96b;
		font-size: 14px;
		border-radius: 2px;
		border: 1px solid #dab96b;
	}
}

.companyProfile_page {
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1480px) {
	.pBox{
		padding:0 2% ;
	}
}
/* Large Devices, Wide Screens */
@media  screen and (max-width: 1200px) {
	.top{margin-bottom: 100px;margin-top: 100px;height: auto !important;}
		/deep/ .desc1{height: 100%;max-height: none;}
}
// @media  screen and (max-width: 992px) {
// 	.top{margin-bottom: 100px;margin-top: 100px;height: auto !important;}
// 		/deep/ .desc1{height: 100%;max-height: none;}
// }
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

	/deep/ .zuiBody {
		width: 90%;
	}
	/deep/ .desc1{width: auto !important;}
	/deep/ .zL {
		width: 150px !important;
	}

	/deep/ .item {
		width: 100% !important;
	}
}


/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
	/deep/ .zuiBody {
		width: 95%;
	}
	.itemBox {
		flex-direction: column !important;
	}
	.pBox {
		flex-direction: column !important;
		padding: 0 20px;
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}
</style>
